import { initComponent } from "@common/utilities.js";
import { COMPONENT_CLASS } from "./component-class.js";

const getComponent = async (component, elements) => {
	switch (component) {
		case COMPONENT_CLASS.chartBlock:
			return import(
				/* webpackChunkName: "chart" */
				"@components/chart/chart.js"
			).then((ChartBlock) =>
				initComponent(ChartBlock.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.tabs:
			return import(
				/* webpackChunkName: "tabs" */
				"@components/tabs/tabs.js"
			).then((Tabs) =>
				initComponent(Tabs.default, {
					el: elements,
				})
			);

		case COMPONENT_CLASS.accordion:
			return import(
				/* webpackChunkName: "accordion" */
				"@components/accordion/accordion.js"
			).then((Accordion) =>
				initComponent(Accordion.default, {
					el: elements,
				})
			);

		case COMPONENT_CLASS.timelineAccordion:
			return import(
				/* webpackChunkName: "timeline-accordion" */
				"@components/timeline-accordion/timeline-accordion.js"
			).then((TimelineAccordion) =>
				initComponent(TimelineAccordion.default, {
					el: elements,
				})
			);

		case COMPONENT_CLASS.linkNavigation:
			return import(
				/* webpackChunkName: "link-navigation" */
				"@components/link-navigation/link-navigation.js"
			).then((LinkNavigation) =>
				initComponent(LinkNavigation.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.imageGallery:
			return import("@components/image-gallery/image-gallery.js").then(
				(imageGallery) => {
					initComponent(imageGallery.default, { el: elements });
				}
			);
		case COMPONENT_CLASS.carousel:
			return import("@components/carousel/carousel.js").then(
				(carousel) => {
					initComponent(carousel.default, { el: elements });
				}
			);
		case COMPONENT_CLASS.headerSmall:
			return import(
				/* webpackChunkName: "header--small" */
				"@components/header/header-small.js"
			).then((HeaderSmall) =>
				initComponent(HeaderSmall.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.headerNavigation:
			return import(
				/* webpackChunkName: "header--navigation" */
				"@components/header/header-navigation.js"
			).then((HeaderNavigation) =>
				initComponent(HeaderNavigation.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.headerGeneric:
			return import(
				/* webpackChunkName: "headerGeneric" */
				"@components/header/header-generic.js"
			).then((HeaderGeneric) =>
				initComponent(HeaderGeneric.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.headerFavorite:
			return import(
				/* webpackChunkName: "headerFavorite" */
				"@components/header/header-favorite.js"
			).then((HeaderFavorite) =>
				initComponent(HeaderFavorite.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.manualNews:
			return import(
				/* webpackChunkName: "manual-news" */
				"@components/manual-news/manual-news.js"
			).then((ManualNews) =>
				initComponent(ManualNews.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.hovernavigation:
			return import(
				/* webpackChunkName: "hovernavigation" */
				"@components/hovernavigation/hovernavigation.js"
			).then((Hovernavigation) =>
				initComponent(Hovernavigation.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.videoPlaylist:
			return import(
				/* webpackChunkName: "video-playlist" */
				"@components/video-playlist/video-playlist.js"
			).then((VideoPlaylist) =>
				initComponent(VideoPlaylist.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.form:
			return import(
				/* webpackChunkName: "umbraco-forms-kruso" */
				"@components/form/form.js"
			).then((Form) =>
				initComponent(Form.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.menu:
			return import(
				/* webpackChunkName: "menu-js" */
				"@components/menu/menu.js"
			).then((Menu) =>
				initComponent(Menu.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.videoThumbs:
			return import(
				/* webpackChunkName: "video-thumbs-js" */
				"@components/video-thumbs/video-thumbs.js"
			).then((VideoThumbs) =>
				initComponent(VideoThumbs.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.registrationsForm:
			return import(
				/* webpackChunkName: "js-registrations-form" */
				"@components/registrations/registrationsForm.js"
			).then((registrationsForm) =>
				initComponent(registrationsForm.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.introBox:
			return import(
				/* webpackChunkName: "js-intro-box" */
				"@components/intro-box/intro-box.js"
			).then((introBox) =>
				initComponent(introBox.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.videoTestimonial:
			return import(
				/* webpackChunkName: "js-video-testimonial" */
				"@components/video-testimonial/video-testimonial.js"
			).then((videoTestimonial) =>
				initComponent(videoTestimonial.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.toTop:
			return import(
				/* webpackChunkName: "to-top" */
				"@components/to-top/to-top.js"
			).then((toTop) =>
				initComponent(toTop.default, {
					el: elements,
				})
			);
		case COMPONENT_CLASS.tableOfContent:
			return import(
				/* webpackChunkName: "table-of-content" */
				"@components/table-of-content/table-of-content.js"
			).then((tableOfContent) =>
				initComponent(tableOfContent.default, {
					el: elements,
				})
			);
	}
};

export { getComponent };
