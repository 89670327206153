import { getComponent } from "./get-component.js";

const init = (component) => {
	const elements = document.querySelectorAll(`.${component}`);

	if (elements?.length < 1) return;

	getComponent(component, elements)?.catch((error) =>
		console.log(
			`Something went wrong while rendering component: ${component}
			`,
			error
		)
	);
};

const bootstrapper = (components) => {
	if (!components) return;

	const moduleList = Object.values(components);

	document.addEventListener("DOMContentLoaded", () =>
		moduleList.forEach((module) => init(module))
	);
};

export { bootstrapper };
