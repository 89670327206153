/* eslint-disable */

/**
 * Class: utilities
 * Description: Utilities functions
 */
import "./polyfills.js";
import Emitter from "./emitter";
export const emitter = new Emitter();

export const html = document.querySelectorAll("html");
export const body = document.querySelectorAll("body");
export const main = document.querySelectorAll("#main");
const url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

export let modules = [];
window.modules = modules;

export function initComponent(moduleClass, config) {
	// init new modules and
	if (config && config.el) {
		if (typeof config.el === "string") {
			config.el = document.querySelectorAll(config.el);
		}
		if (typeof config.el == "object") {
			config.el.forEach(function (el, index) {
				// let localConfig = { ...config, ...{ el: el } };
				let localConfig = Object.assign({}, config, { el: el });
				if (localConfig.name) {
					if (index > 0) {
						console.warn(
							"Module init: more than one module with the selector exists in the dom, be aware that there will only be on in the modulelist"
						);
					}
					modules[localConfig.name] = new moduleClass(localConfig);
				} else {
					modules.push(new moduleClass(localConfig));
				}
			});
		}
	} else {
		console.warn(
			"Module init: Please check that the provided selector and module-class exists"
		);
	}
}

export function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) {
				func.apply(context, args);
			}
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait || 200);
		if (callNow) {
			func.apply(context, args);
		}
	};
}
export function isMobile() {
	return this.getSize() == "xsmall" || this.getSize() == "small";
}
// get size of window.
export function getSize() {
	setTimeout(() => {
		var body = document.querySelector("body");
		return window
			.getComputedStyle(body, ":before")
			.getPropertyValue("content")
			.replace(/\"/g, "");
	}, 0);
}

var lastSize = getSize();
window.addEventListener(
	"resize",
	debounce(function () {
		const size = getSize();
		emitter.emit("responsive/resize", size);
		if (size !== lastSize) {
			emitter.emit("responsive/sizechange", size);
			lastSize = size;
		}
	}, 50)
);
var prevScrollpos = window.pageYOffset;
window.addEventListener(
	"scroll",
	debounce(function () {
		var currentScrollPos = window.pageYOffset;
		var direction = "up";
		if (prevScrollpos - 5 > currentScrollPos) {
			// add some buffer so we dont get flickr
			direction = "up";
		} else if (prevScrollpos + 20 < currentScrollPos) {
			// add som buffer so the user can scroll slowly down the page without this triggering
			direction = "down";
		} else {
			direction = "";
		}
		prevScrollpos = currentScrollPos;
		emitter.emit("window/scroll", {
			top: window.pageYOffset,
			direction: direction,
		});
	}, 10)
);
export function setHashUrl(hash) {
	if (history.replaceState) {
		history.replaceState(null, null, url + "#" + hash);
	} else {
		location.hash = "#" + hash;
	}
}

export function resetHashUrl() {
	if (history.replaceState) {
		history.replaceState(null, null, url);
	} else {
		location.hash = "";
	}
}

export function getElementFromHash(element) {
	let hash = window.location.hash;
	if (hash) {
		hash = decodeURI(hash);

		if (element && "#" + element.getAttribute("id") === hash) {
			return element;
		}

		const elementMatch = element
			? element.querySelector(`[id="${hash.substring(1)}"]`)
			: document.querySelector(`[id="${hash.substring(1)}"]`);

		return elementMatch;
	}
	return null;
}

export function setAriaProp(el, prop, value) {
	el.setAttribute("aria-" + prop, value);
}

export function hasContent(
	el,
	checkHTML = false,
	checkText = true,
	checkImages = true,
	checkIframes = true,
	checkVideos = true
) {
	const hasText = el.textContent.replace(/\s/g, "") !== "";
	const hasHTML = el.innerHTML.replace(/\s/g, "") !== "";
	const hasVideos = el.querySelector("video") !== null;
	const hasIframes = el.querySelector("iframe") !== null;
	const hasImages = el.querySelector("img") !== null;

	if (checkHTML && hasHTML) return true;
	if (checkText && hasText) return true;
	if (checkVideos && hasVideos) return true;
	if (checkIframes && hasIframes) return true;
	if (checkImages && hasImages) return true;

	return false;
}

export const focusableElementsSelector =
	'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

export function trapFocus(element) {
	const focusableEls = element.querySelectorAll(focusableElementsSelector);
	const firstFocusableEl = focusableEls[0];
	const lastFocusableEl = focusableEls[focusableEls.length - 1];
	const KEYCODE_TAB = 9;

	element.addEventListener("keydown", (e) => {
		const isTabPressed = e.key === "Tab" || e.keyCode === KEYCODE_TAB;

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) {
			/* shift + tab */ if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */ else {
			if (document.activeElement === lastFocusableEl) {
				firstFocusableEl.focus();
				e.preventDefault();
			}
		}
	});
}
